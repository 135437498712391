
<template>
<form autocomplete="off" class="">
    <is-loading v-if="isLoading" :box="true" />
    <div class="row gx-lg-4 g-3">
        <div class="col-md-4">
            <label for="category_id" class="form-label">Category</label>
            <select @change="categoryChange()" v-model="data.category_id" 
                name="category_id" id="category_id" class="form-select">
            <option disabled value="" >--choose category--</option>
            <option v-for="(category, coIndex) in categories" :key="coIndex" 
            :value="category.id">{{category.title}}</option>
            </select>
        </div>
        <div class="col-md-4">
            <label> Sub Category </label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="title" label="title"
            :options="subCategoryOptions" 
            v-model="data.sub_category_id" placeholder="--select --"></multiselect>
        </div> 
        <div class="col-md-4">
            <label> Type </label>
            <multiselect 
            mode="single"
            searchable
            valueProp="id"
            trackBy="title" label="title"
            :options="medicineTypes" 
            v-model="data.medicine_type_id" placeholder="--select --"></multiselect>
        </div> 
    </div>
    <div class="row mt-3 justify-content-end">
        <div class="col-12">
            <div class="mt-3 text-end">
            <b-button variant="primary" class="me-3"
                @click.prevent="getList()"
                type="button"> Get List </b-button>

            <b-button variant="dark" 
                @click.prevent="resetForm()"
                type="button"> Reset </b-button>
            </div>
        </div>
    </div>
</form>
</template>
    
<script>
import Multiselect from '@vueform/multiselect'
import IsLoading from '@/components/IsLoading.vue'

export default {
    components: {
        Multiselect,
        IsLoading
    },
    data() {
    return {
        isLoading: false,
        data: {
            category_id: "",
            sub_category_id: "",
            medicine_type_id: ""
        },
        subCategoryOptions:[],
        mirrorData: null
    }
    },
    computed:{
        categories(){
            return this.$store.state.category.list
        },
        medicineTypes(){
            return this.$store.state.medicineType.list
        },
    },
    methods: {
        getList() {
            var payload = Object.fromEntries(
                Object.entries(this.data).filter(([_, v]) => !!v )
            );
            this.$emit('startSearch', payload)
        },
        getOptionData(){
            this.$store.dispatch('category/fetchAllCategories')
            this.$store.dispatch('medicineType/fetchAllMedicineTypes')
            .then(response => this.isLoading = false )
        },
        categoryChange(initialize = true){
            if(initialize){
                this.data.sub_category_id = null;
            }
            this.subCategoryOptions = [];
            if(!this.data.category_id) { return; }
            this.isLoading = true;
            this.$http.get(`${this.absoluteUrl()}/api/sub-categories?category_id=${this.data.category_id}`)
                .then((response) => {
                this.isLoading = false;
                if(response.data.success){
                    this.subCategoryOptions = response.data.data
                }
            })
        },
        resetForm() {
            this.data = {...this.mirrorData}
            this.$emit('resetFilters')
        },
    },
    created(){
        this.getOptionData()
    },
    mounted(){
        this.mirrorData = JSON.parse(JSON.stringify(this.data))
    },
}
</script>
